import Cmp from './ProjectPage'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query projectPageQuery($prismicId: ID!) {
    prismicProject(prismicId: { eq: $prismicId }) {
      id
      uid
      prismicId
      data {
        title {
          text
        }
        headline {
          text
        }
        project_image
        {
          url
        }
        project_color
        case_study
        body {
          __typename
          ... on PrismicProjectBodyProjectHero {
            primary {
              hero_title {
                html
                text
              }
              hero_subtitle {
                html
                text
              }
              hero_background {
                alt
                copyright
                url
              }
              hero_image {
                alt
                copyright
                url
              }
            }
          }
          ... on PrismicProjectBodyProjectInfo {
            primary {
              info_title {
                text
              }
              info_text {
                html
              }
              info_logo {
                url
                alt
              }
              info_expertise {
                html
              }
              info_link_text {
                html
                text
              }
              info_link {
                url
              }
              info_year {
                text
              }
            }
          }
          ... on PrismicProjectBodyImageIpadIphone {
            primary {
              img_one {
                url
                alt
              }
              img_two {
                url
                alt
              }
              img_text {
                html
              }
            }
          }
          ... on PrismicProjectBodyImageSingle {
            primary {
              img_one {
                url
                alt
              }
              img_text {
                html
              }
            }
          }
          ... on PrismicProjectBodyImageTriple {
            primary {
              img_one {
                url
                alt
              }
              img_two {
                url
                alt
              }
              img_three {
                url
                alt
              }
              img_text {
                html
              }
            }
          }
          ... on PrismicProjectBodyTwoColWImage {
            primary {
              headline {
                text
              }
              text {
                html
              }
              img {
                url
                alt
              }
              col_order
            }
          }
          ... on PrismicProjectBodyTwoColQuote {
            primary {
              headline {
                text
              }
              quote {
                html
                text
              }
              author {
                text
              }
              job_title {
                text
              }
              author_img {
                url
                alt
              }
            }
          }
          ... on PrismicProjectBodyMoreWork {
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
              link_text {
                text
              }
              link {
                url
              }
            }
            items {
              project {
                url
                document {
                  __typename
                  ... on PrismicProject {
                    data {
                      title {
                        html
                        text
                      }
                      headline {
                        text
                      }
                      project_color
                      case_study
                      project_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Cmp
