import React from 'react'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import Main from '../../layouts/Main'
import { ScrollSectionProvider } from '../../context/ScrollContext'
import { sliceMapping } from '../../utils/sliceMapping'
import { Transition } from '../../components/'
import Helmet from 'react-helmet'
import makeSlices from '../../prismic/Slices'
import { useLocation } from '@reach/router'

const Slices = makeSlices(sliceMapping)

const IS_BROWSER = typeof window !== 'undefined'

const ProjectPage = ({ data: staticData, ...props }) => {
  const location = useLocation()
  const sections = []

  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  const content = data.prismicProject.data

  return (
    <>
      <ScrollSectionProvider value={sections} >
        <Main redFooter whiteBG>
          <Transition>
            <Slices
              body={content.body}
              color={content.project_color}
              caseStudy={content.case_study}
            />
          </Transition>
        </Main>
      </ScrollSectionProvider>
      <Helmet>
        <meta property='og:title' content={`${content.title.text} | Kosmos & Kaos`} />
        <meta property='og:description' content={content.headline.text} />
        <meta property='og:url' content={location.href} />
        <meta property='og:image' content={content.project_image.url} />
        <meta name='twitter:image:src' content={content.project_image.url} />
      </Helmet>
    </>
  )
}

export default ProjectPage
